<template>
  <section class="page-section sites-section">
    <div class="section-header sites-and-licenses mb-3 mb-sm-0">
      <div class="d-flex flex-column">
        <h2>{{ $t('Sites & Licenses') }}</h2>

        <!--
        <div v-if="!user.iscommunityedition" class="section-header-nav mt-1">
          <button
            class="btn btn-link btn-sm px-0 mx-0"
            @click="$modal.show('trial')"
          >
            {{ $t('Want to try FileCloud Enterprise? Start a new trial') }}
          </button>
        </div>
        -->
      </div>

      <ul class="refinement-menu">
        <li>
          <DropDownList
            :items="filterOptions"
            @triggerDDAction="onFilter"
            @click.native.stop
          >
            <a
              ><Icon name="filter" family="fas" /> Show <b>{{ filterBy }}</b>
              <Icon name="chevron-down" family="far"
            /></a>
          </DropDownList>
        </li>
        <li>
          <DropDownList
            :items="sortOptions"
            @triggerDDAction="onSort"
            @click.native.stop
          >
            <a
              ><Icon name="filter" family="fas" /> Sort by
              <b v-if="sortBy"
                >{{ sortTitles[sortBy] }} ({{
                  sortDirection ? 'desc' : 'asc'
                }})</b
              >
              <Icon name="chevron-down" family="far"
            /></a>
          </DropDownList>
        </li>
      </ul>

      <InputBox
        v-model="searchStr"
        :placeholder="$t('Search for sites...')"
        :appends="[
          searchStr
            ? { icon: 'times', action: () => (searchStr = '') }
            : { icon: 'search' },
        ]"
        rounded
        form-size="sm"
      />

      <ul class="ml-0 refinement-menu">
        <li>
          <DropDownList
            :items="moreOptions"
            @triggerDDAction="onChooseMore"
            @click.native.stop
          >
            <a><Icon name="ellipsis-v" family="fas" /></a>
          </DropDownList>
        </li>
      </ul>
    </div>

    <div class="section-body has-divider">
      <div v-if="isLoading && licenses.length === 0" class="row card-sites-row">
        <div
          v-for="(license, index) in [0, 1, 2, 3]"
          :key="`loader-${index}`"
          class="col-md-3"
        >
          <div class="card card-site card-site--loading">
            <div class="card-body">
              <div class="card-logo"></div>
              <h5 class="card-title"></h5>
              <p class="card-text"></p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoading || licenses.length > 0" class="row card-sites-row">
        <div
          v-for="(license, index) in licenses"
          :key="license.issuedate + index"
          class="col-md-3"
        >
          <router-link
            v-if="viewAll || index <= 1"
            :to="getCardLink(license)"
            class="card card-site"
            @click.native.prevent="onClickCard(license, $event)"
          >
            <div class="card-body">
              <div
                class="card-logo"
                :class="{
                  ['is-loading']:
                    !license.ispending &&
                    license.deploymenturl &&
                    license.licensegroup === 'Online' &&
                    !(
                      hasLogoLoaded[license.deploymenturl] ||
                      hasLogoFailed[license.deploymenturl]
                    ),
                }"
              >
                <img
                  v-if="
                    !license.ispending &&
                    license.deploymenturl &&
                    license.licensegroup === 'Online' &&
                    !hasLogoFailed[license.deploymenturl]
                  "
                  alt=""
                  :src="`https://${license.deploymenturl}/core/getcustomimage?type=login`"
                  @error="onFailed(license.deploymenturl)"
                  @load="onLoad(license.deploymenturl)"
                />
                <Logo v-else viewBox="0 0 250 60" />
                <div
                  v-if="
                    license.istrial &&
                    !license.deploymenturl &&
                    license.licensegroup === 'Online' &&
                    !(
                      hasLogoLoaded[license.deploymenturl] ||
                      hasLogoFailed[license.deploymenturl]
                    )
                  "
                  class="badge"
                  :class="{ ['badge-expired']: license.daysremaining < 1 }"
                >
                  {{ $t('Trial') }}
                </div>
                <div v-if="license.ispending" class="badge badge-pending">
                  {{ $t('Pending') }}
                </div>
              </div>
              <h5
                class="card-title"
                :class="{ community: license.licensegroup === 'Community' }"
                v-html="getSiteName(license)"
              ></h5>
              <p
                v-if="license.licensegroup !== 'Community'"
                class="card-text ellipsis"
              >
                <Icon name="users" family="fas" />
                {{
                  $tc(
                    'no seat | {count} seat | {count} seats',
                    license.numofaccounts
                  )
                }}
              </p>

              <p
                v-if="license.ispending && license.licensegroup !== 'Community'"
                class="card-text ellipsis"
              >
                <Icon name="clock" family="fas" />
                {{ $t('The license is being issued') }}
              </p>

              <button
                v-if="license.licensegroup === 'Community'"
                class="btn btn-primary col"
              >
                {{ $t('DOWNLOAD NOW') }}
              </button>
              <p
                v-else
                class="card-text ellipsis"
                :class="{
                  ['text-warning']:
                    license.daysremaining > 0 && license.daysremaining < 5,
                  ['text-danger']: license.daysremaining < 1,
                }"
              >
                <Icon name="clock" family="fas" />
                {{
                  $tc(
                    'The license is expired | Expires in {count} day | Expires in {count} days',
                    license.daysremaining > 0 ? license.daysremaining : 0
                  )
                }}
              </p>
              <Icon v-if="license.licensegroup !== 'Community'" name="chevron-right" family="far" />
            </div>
          </router-link>

          <a
            v-else-if="!viewAll && index === 2"
            class="card card-site view-all"
            @click="toggleViewAll"
          >
            {{ $t('View all') }}
          </a>
        </div>

        <div class="col-md-3">
          <div
            class="card card-site card-site--empty"
            @click="$modal.show('checkout')"
          >
            <div class="card-body">
              <div class="card-logo">
                <Icon name="plus" />
              </div>
              <h5 class="card-title">{{ $t('New license') }}</h5>
              <p class="card-text">
                {{ $t('Click here to buy a new license') }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="viewAll && licenses.length >= 2" class="col-md-12">
          <a
            class="card card-site view-all view-all-block"
            @click="toggleViewAll"
          >
            {{ $t('View less') }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import Icon from 'common/components/Icon.vue';
import InputBox from '@/components/InputBox.vue';
import Logo from '@/assets/logo.svg';
import DropDownList from 'common/components/DropDownList';

import Fuse from 'fuse.js';
import { getSiteName, getCardLink } from '../utils/licenses';

export default {
  name: 'SitesAndLicenses',
  components: {
    Logo,
    Icon,
    InputBox,
    DropDownList,
  },
  data() {
    return {
      searchStr: '',
      hasLogoFailed: {},
      hasLogoLoaded: {},
      filterBy: 'all',
      sortBy: '',
      sortDirection: true,
      sortTitles: {
        deploymenturl: 'Domain name',
        issuedate: 'Issue Date',
        daysremaining: 'Expiration',
        numofaccounts: 'Number of seats',
        _meta: 'License Issue',
      },
    };
  },
  computed: {
    moreOptions() {
      return [
        {
          name: 'Download licenses report',
          icon: 'download',
          action: 'report',
        },
      ];
    },

    filterOptions() {
      return [
        {
          name: 'Show all',
          icon: this.filterBy === 'all' ? 'check' : 'none',
          action: 'all',
        },
        {
          name: 'Show active',
          icon: this.filterBy === 'active' ? 'check' : 'none',
          action: 'active',
        },
        {
          name: 'Show pending',
          icon: this.filterBy === 'pending' ? 'check' : 'none',
          action: 'pending',
        },
        {
          name: 'Show expired',
          icon: this.filterBy === 'expired' ? 'check' : 'none',
          action: 'expired',
        },
      ];
    },
    sortOptions() {
      return [
        {
          name: 'Default',
          icon: !this.sortBy ? 'check' : 'none',
          action: '',
        },
        {
          name: 'Domain name',
          icon:
            this.sortBy === 'deploymenturl'
              ? this.sortDirection
                ? 'sort-down'
                : 'sort-up'
              : 'none',
          action: 'deploymenturl',
        },
        {
          name: 'Issue Date',
          icon:
            this.sortBy === 'issuedate'
              ? this.sortDirection
                ? 'sort-down'
                : 'sort-up'
              : 'none',
          action: 'issuedate',
        },
        {
          name: 'Expiration',
          icon:
            this.sortBy === 'daysremaining'
              ? this.sortDirection
                ? 'sort-down'
                : 'sort-up'
              : 'none',
          action: 'daysremaining',
        },
        {
          name: 'Number of seats',
          icon:
            this.sortBy === 'numofaccounts'
              ? this.sortDirection
                ? 'sort-down'
                : 'sort-up'
              : 'none',
          action: 'numofaccounts',
        },
        {
          name: 'License Issue',
          icon:
            this.sortBy === '_meta'
              ? this.sortDirection
                ? 'sort-down'
                : 'sort-up'
              : 'none',
          action: '_meta',
        },
      ];
    },
    user() {
      return this.$store.state.core.user;
    },
    licenseDaysRemaining() {
      return this.site.daysremaining;
    },
    licenses() {
      let licenses = this.$store.state.sites.licenses.map((license) => {
        return {
          ...license,
          _meta: `${license.istrial ? this.$t('trial') : ''} ${
            license.daysremaining <= 0 ? this.$t('expired') : ''
          } ${
            license.licensegroup === 'Online'
              ? this.$t('online')
              : this.$t('server')
          } ${license.iscommunity ? this.$t('community') : ''}`,
        };
      });

      switch (this.filterBy) {
        case 'active':
          licenses = _.filter(licenses, (item) => item.daysremaining > 0);
          break;
        case 'expired':
          licenses = _.filter(
            licenses,
            (item) => !item.ispending && item.daysremaining <= 0
          );
          break;
        case 'pending':
          licenses = _.filter(licenses, (item) => item.ispending);
          break;
      }

      // sort by
      if (this.sortBy) {
        licenses = _.orderBy(
          licenses,
          [this.sortBy],
          [this.sortDirection ? 'desc' : 'asc']
        );
      } else {
        licenses = _.orderBy(licenses, { iscommunity: false });
      }

      if (this.searchStr) {
        const fuse = new Fuse(licenses, {
          keys: ['deploymenturl', '_meta'],
          distance: 200,
          threshold: 0.3,
        });

        licenses = fuse.search(this.searchStr).map((i) => i.item);
      }

      if (!this.viewAll) {
        licenses = licenses ? licenses.slice(0, 3) : undefined;
      }

      return licenses;
    },
    viewAll() {
      return this.$store.state.sites.viewAll;
    },
    isLoading() {
      return this.$store.state.loading['sites/getSites'];
    },
  },
  watch: {
    filterBy() {
      this.onChangeFilter();
    },
    sortBy() {
      this.onChangeFilter();
    },
  },
  methods: {
    onChangeFilter() {
      if (!this.viewAll) {
        this.toggleViewAll();
      }
    },
    getSiteName,
    toggleViewAll() {
      this.$store.dispatch('sites/toggleViewAll');
    },
    onLoad(site) {
      Vue.set(this.hasLogoLoaded, site, true);
    },
    onFailed(site) {
      Vue.set(this.hasLogoFailed, site, true);
    },
    getCardLink,
    onFilter(data) {
      this.filterBy = data;
    },
    onChooseMore(option) {
      if (option === 'report') {
        this.$store.dispatch('sites/downloadLicensesInfo');
      }
    },
    onSort(data) {
      if (data === this.sortBy) {
        this.sortDirection = !this.sortDirection;
      } else {
        this.sortBy = data;
        this.sortDirection = true;
      }
    },
    onClickCard(license, e) {
      if (license.ispending) {
        e.preventDefault();
        e.stopPropagation();

        this.$modal.show('checkout', {
          step: 'summary',
          props: { orderNumber: license.order_number },
        });
      }
    },
  },
};
</script>
