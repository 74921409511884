<template>
  <section class="page-section">
    <div class="section-header">
      <h2>{{$t('Useful Resources')}}</h2>
    </div>
    <div class="section-body" :class="{['has-divider']: hasDivider && ((posts.length === 0 && isLoadingBlog) || posts.length > 0)}">

      <div class="row resources-row">
        
        <div v-if="!user.iscommunityedition" class="col-md-3">
          <div class="resource-block">
            <h4>{{$t('Get started')}}</h4>
            <div class="resource-block-body">
              <a class="card card-resource" href="https://www.filecloud.com/sales-team/" target="_blank" @click="onScheduleDemo">
                <DemoIllustration viewBox="0 0 133 133" />
                <div class="card-content">
                  <h5>{{$t('Need guidance?')}}</h5>
                  <p>{{$t('Schedule a free demo')}}</p>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="col-6 col-md-3">
          <div class="resource-block">
            <h4>{{$t('Learn More')}}</h4>
            <div class="resource-block-body">
              <ul class="has-divider">
                <li><a target="_blank" href="https://www.filecloud.com/customer-stories/">{{$t('Case Studies')}}</a></li>
                <li><a target="_blank" href="https://www.filecloud.com/datasheets/">{{$t('Data Sheet / FAQ')}}</a></li>
                <li><a target="_blank" href="https://www.filecloud.com/supportdocs/">{{$t('Documentation')}}</a></li>
                <li><a target="_blank" href="https://www.filecloud.com/releasenotes/">{{$t('Release Notes')}}</a></li>
                <li><a target="_blank" href="https://ideas.filecloud.com/">{{$t('Suggest an idea')}}</a></li>
                <li><a target="_blank" href="https://www.filecloud.com/supportdocs/display/cloud/FileCloud+Developer+Guide">{{$t('Developers')}}</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="showDownloadSection" class="col-6 col-md-3">
          <div class="resource-block">
            <h4>{{$t('Downloads')}}</h4>
            <div class="resource-block-body">
              <ul>
                <li><router-link to="/downloads/server">{{$t('Server')}}</router-link></li>
                <li><router-link to="/downloads/sync">{{$t('Sync')}}</router-link></li>
                <li><router-link to="/downloads/drive">{{$t('Drive')}}</router-link></li>
                <li><router-link to="/downloads/secure">{{$t('Secure Document Viewer')}}</router-link></li>
                <li><router-link to="/downloads/mobile">{{$t('Mobile Apps')}}</router-link></li>
                <li><router-link to="/downloads/other">{{$t('Other Downloads')}}</router-link></li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

import DemoIllustration from '@/assets/illustrations/demo.svg';

export default {
  name: 'UsefulResources',
  components: {
    DemoIllustration
  },
  props: {
    hasDivider: {
      type: Boolean,
      default: true
    },
    site: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    user() {
      return this.$store.state.core.user;
    },
    showDownloadSection() {
      return this.$store.getters['core/showDownloadSection'];
    },
    posts() {
      return this.$store.state.core.blogPosts;
    },
    isLoadingBlog() {
      return this.$store.state.loading['core/getBlog'];
    }
  },
  methods: {
    onScheduleDemo() {
      this.$store.dispatch('core/sendAnalyticsEvent', {
        category: 'cerebro',
        action: 'contact-us-demo',
        label: this.user.email,
      });
    },
  }
}
</script>