<template>
  <main
    role="main"
    class="main-wrapper"
  >

    <SitesAndLicenses />

    <UsefulResources />

    <section
      v-if="(posts.length === 0 && isLoadingBlog) || posts.length > 0"
      class="page-section position-relative"
    >
      <div class="section-header">
        <h2>{{ $t('Latest News') }}</h2>
      </div>
      <div class="section-body">
        <div v-if="isLoadingBlog" class="row">
          <div v-for="post in [0, 1, 2, 3]" :key="post" class="col-md-3">
            <a class="card card-blog card-blog--loading">
              <div class="card-date"></div>
              <div class="card-body">
                <h5 class="card-title"></h5>
              </div>
            </a>
          </div>
        </div>
        <div v-else class="row posts-row blog-row">
          <Carousel
            :stage-padding="15"
            :dots="false"
            :nav="false"
            :margin="16"
            :responsive="{
              0: { items: 1 },
              500: { items: 2 },
              750: { items: 3 },
              1000: { items: 4 },
            }"
          >
            <div v-for="post in posts" :key="post.id">
              <a class="card card-blog" :href="post.link" target="_blank">
                <div class="card-date">
                  <div class="date-header">{{getBlogItemMonth(post.date)}}</div>
                  <div class="date-day">{{getBlogItemDay(post.date)}}</div>
                </div>
                <div class="card-body">
                  <h5 class="card-title">{{ post.title }}</h5>
                  <Icon family="fas" name="caret-right" />
                </div>
              </a>
            </div>

            <template #prev>
              <button class="btn carrosel-posts-nav">
                <Icon family="fas" name="chevron-left" />
              </button>
            </template>

            <template #next>
              <button class="btn carrosel-posts-nav">
                <Icon family="fas" name="chevron-right" />
              </button>
            </template>
          </Carousel>
        </div>
      </div>
    </section>

    <vue-headful :title="$t('Dashboard - FileCloud Customer Portal')" />
  </main>
</template>

<script>
import UsefulResources from '@/components/Partials/UsefulResources.vue';
import SitesAndLicenses from '@/components/SitesAndLicenses.vue';

import Icon from 'common/components/Icon';

import moment from 'moment';
import Carousel from 'vue-owl-carousel';

export default {
  name: 'Dashboard',
  components: {
    UsefulResources,
    SitesAndLicenses,
    Carousel,
    Icon,
  },
  computed: {
    user() {
      return this.$store.state.core.user;
    },
    licenses() {
      return this.$store.state.sites.licenses;
    },
    posts() {
      return this.$store.state.core.blogPosts;
    },
    isLoadingBlog() {
      return this.$store.state.loading['core/getBlog'];
    }
  },
  methods: {
    getBlogItemMonth(date) {
      return moment(date).format('MMM')
    },
    getBlogItemDay(date) {
      return moment(date).format('D')
    }
  }
};
</script>
